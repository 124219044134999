<script>
import ApiService from '../../service/api'
import Toastify from 'toastify-js'
import JsPDF from 'jspdf'
import { imgData } from '../../assets/images/index'
const service = new ApiService()
// import { jsPDF } from 'jspdf'
// const pdf = new jsPDF()
// import 'jspdf-autotable'
// import { imgData } from '../../assets/images/index'

export default {
  components: {
    // jsPDF
  },
  data() {
    return {
      orderData: [],
      paket: [],
      // opsibayar: [],
      formReg: {},
      detailsOrder: '',
      showModal: true,
      dd: true
    }
  },
  mounted() {
    this.loadOrder()
    this.loadPaket()
    // this.loadOpsiBayar()
  },
  methods: {
    loadOrder() {
      try {
        service.serviceGetOrder().then((response) => {
          switch (response.status) {
            case 200:
              this.orderData = response.results
              // console.table(this.orderData)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    loadPaket() {
      try {
        service.servicePaket().then((response) => {
          // console.log(response)
          switch (response.status) {
            case 200:
              this.paket = response.results
              // console.table(this.paket)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    // loadOpsiBayar() {
    //   try {
    //     service.serviceOpsi().then((res) => {
    //       switch (res.status) {
    //         case 200:
    //           this.opsibayar = res.results
    //           console.table(this.opsibayar)
    //           break
    //         case 400:
    //           console.log(res.message)
    //           break
    //         default:
    //           console.log('Error')
    //           break
    //       }
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    createOrder() {
      try {
        service.serviceRegisterProduct(this.formReg).then((res) => {
          switch (res.status) {
            case 200:
              this.showModal = false
              location.reload()
              break
            default:
              console.log(res)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    downloadInvoice(data) {
      const totHarga = data.harga - data.hargaPotongan
      const pdf = new JsPDF()

      // console.log(imgData);

      // pdf.text("IMAGE", 80, 10);
      pdf.addImage(imgData, 'PNG', 80, 10, 50, 20)
      pdf
        .setFont(undefined, 'bold')
        .text('PEMBAYARAN BERHASIL', 73, 40)
        .setFont(undefined, 'normal')
      pdf
        .setFontSize(11)
        .setFont(undefined, 'bold')
        .text('Terima kasih atas pembayaran anda', 75, 50, 0, 10)
        .setFont(undefined, 'normal')
      pdf
        .setFontSize(11)
        .setFont(undefined, 'bold')
        .text(
          'Kalenderkonten akan selalu menjadi pilihan terbaik anda',
          55,
          55,
          0,
          10
        )
        .setFont(undefined, 'normal')

      pdf
        .setFontSize(22)
        .setFont(undefined, 'bold')
        .text('No. Invoice', 10, 80, 0, 10)
        .setFont(undefined, 'normal')
      pdf.setFontSize(13).text('#' + data.invNumber, 10, 90, 0, 10)

      pdf
        .setFontSize(18)
        .setFont(undefined, 'bold')
        .text('Client details', 10, 110, 0, 10)

      pdf.text('Payment to', 167, 110, 0, 10).setFont(undefined, 'normal')

      pdf.setFontSize(13).text(data.nama, 10, 120, 0, 10)
      pdf.text('kalenderkonten.com', 160, 120, 0, 10)

      pdf.text(data.email, 10, 127, 0, 10)
      pdf
        .text('sales@kalenderkonten.com', 144, 127, 0, 10)
        .setFont(undefined, 'normal')

      pdf.setFont(undefined, 'bold').text('@' + data.akunIG, 10, 135, 0, 10)

      pdf.autoTable({
        margin: { top: 145 },
        headStyles: {
          fillColor: [0, 0, 0, 0.3],
          fontStyle: 'bold',
          fontSize: 13
        },
        head: [['Deskripsi', 'Harga', 'Potongan', 'Vat']],
        body: [
          [
            `Paket ${data.nama_paket} ${data.durasi}  Bulan`,
            `Rp. ${new Intl.NumberFormat('de-DE').format(data.harga)},-`,
            data.disc,
            `Rp. ${new Intl.NumberFormat('de-DE').format(data.hargaVat)},-`
          ]
          //, ["Castille", "castille@example.com", "Spain"],
        ]
      })

      pdf.text('Total Pembayaran', 162, 200, 0, 10)
      pdf
        .setFontSize(20)
        .text(
          'Rp ' + new Intl.NumberFormat('de-DE').format(totHarga) + ',-',
          154,
          210,
          0,
          10
        )
        .setFont(undefined, 'normal')

      pdf.setFontSize(11).text('Sudah termasuk pajak', 162, 220, 0, 10)
      pdf.setFontSize(20).text('Pembayaran Lunas', 140, 230, 0, 10)

      pdf.save('invoice.pdf')
    },

    sendInvoice(data) {
      try {
        service.servicePostEmail(data).then((res) => {
          console.log(res)
          switch (res.status) {
            case 200:
              this.toasty('#notification-content')
              break
            default:
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    renewInvoice() {
      try {
        console.log('renew invoice')
      } catch (error) {
        console.log(error)
      }
    },

    deleteOrder() {
      try {
        service.serviceDeleteRegPaket(this.formReg.idRegister).then((res) => {
          console.log(res)
          switch (res.status) {
            case 200:
              this.showModal = false
              location.reload()
              break
            default:
              console.log(res)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    toasty(data) {
      Toastify({
        node: cash(data).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'left',
        stopOnFocus: true
      }).showToast()
    }
  }
}
</script>
